import { createElement } from "react";
import { ElementFactory, Question, Serializer } from "survey-core";
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from "survey-react-ui";
import "../../styles/datePicker.css";
import { SelectDatepicker } from "react-select-datepicker";

import { formatDate } from "../../utils/dateFormat";

// A model for the new question type
export class QuestionDatePickerModel extends Question {
  getType() {
    return "date-picker";
  }
  setValue(value) {
    this.value = value;
  }
}
// Register `QuestionDatePickerModel` as a model for the `date-picker` type
export function registerDatePicker() {
  ElementFactory.Instance.registerElement("date-picker", (name) => {
    return new QuestionDatePickerModel(name);
  });
}
// Add question type metadata for further serialization into JSON
Serializer.addClass(
  "date-picker",
  [],
  function () {
    return new QuestionDatePickerModel("");
  },
  "question"
);
// A class that renders questions of the new type in the UI
export class SurveyQuestionDatePicker extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    const defaultDate = new Date();
    this.question.value = formatDate(defaultDate);
  }

  get question() {
    return this.questionBase;
  }
  get value() {
    return this.question.value;
  }
  handleDateChange = (data) => {
    this.question.value = formatDate(data);
  };
  renderElement() {
    return (
      <SelectDatepicker
        selectedDate={
          this.question.value === "NaN-NaN-NaN"
            ? new Date()
            : new Date(this.question.value)
        }
        onDateChange={(val) => {
          this.handleDateChange(val);
        }}
        maxDate={new Date()}
        labels={{
          dayPlaceholder: "Day",
          monthPlaceholder: "Month",
          yearPlaceholder: "Year",
        }}
      />
    );
  }
}
// Register `SurveyQuestionDatePicker` as a class that renders `date-picker` questions
ReactQuestionFactory.Instance.registerQuestion("date-picker", (props) => {
  return createElement(SurveyQuestionDatePicker, props);
});
