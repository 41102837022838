import LoadingIndicator from "../../loadingIndicator";
import styles from "./styles.module.css";

const LoadingModal = () => {
  return (
    <div>
      <LoadingIndicator />
      <p className={styles.text}>Please wait!</p>
    </div>
  );
};

export default LoadingModal;
