// Set a value in session storage
export const setStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

// Get a value from session storage
export const getStorage = (key) => {
  const value = sessionStorage.getItem(key);
  return value && value !== "undefined" ? JSON.parse(value) : undefined;
};
