export const domainName = process.env.REACT_APP_DOMAIN_NAME;

export const metaPixel = process.env.REACT_APP_META_PIXEL_ID;

export const platformAPI = `${process.env.REACT_APP_DOMAIN_NAME}platform/`;

export const SchedulingURL = process.env.REACT_APP_SCHEDULING_URL;

export const firehoseName = process.env.REACT_APP_FIREHOSE_NAME;

export const clientId = process.env.React_APP_CLIENT_ID;
export const clientSecret = process.env.React_APP_CLIENT_SECRET;

export const message1 = process.env.React_APP_ACTIVE_PARTICIPANT;
export const message2 = process.env.React_APP_INELIGIBLE_PARTICIPANT;
export const message3 = process.env.React_APP_COMPLETED_PARTICIPANT;
export const message4 = process.env.React_APP_PARTICIPANT_STUDY;
export const message5 = process.env.React_APP_ACTIVE_PARTICIPANT_OTHER_STUDY;
