import React from "react";
import styles from "../styles/blank.module.css";
// import { useTracking } from "@velocity/tracking";
import { getStorage } from "../utils/storage";
const ErrorPage = () => {
  // useTracking({
  //   custom_properties: {
  //     studyId: getStorage("encrypted_study_id"),
  //   },
  // });

  return (
    <div>
      <div className={styles.card}>
        <div className={styles.innerCard}>
          <p className={styles.typography}>
            <span className={styles.span}>{getStorage("error_message")}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
