import styles from "./styles.module.css";
const LoadingIndicator = () => {
  return (
    <div className={styles.body} data-testid="loading">
      <div className={styles.container}>
        <div className={styles.dot1}> </div>
        <div className={styles.dot2}></div>
        <div className={styles.dot3}></div>
      </div>
    </div>
  );
};

export default LoadingIndicator;
