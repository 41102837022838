import { Button } from "@material-ui/core";
import React, { useState } from "react";
import HtmlParser from "react-html-parser";
import { useNavigate } from "react-router-dom";
import styles from "../styles/terms.module.css";
import { getStorage } from "../utils/storage";
// import { useTracking } from "@velocity/tracking";

const Terms = () => {
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const navigation = useNavigate();

  // useTracking({
  //   custom_properties: {
  //     studyId: getStorage("encrypted_study_id"),
  //   },
  // });

  const handleSubmit = () => {
    if (checked) {
      navigation("/register");
    } else {
      setError(true);
    }
  };

  const handleChange = (event) => {
    event.target.checked && setError(false);
    setChecked(event.target.checked);
  };

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div>
            <p className={styles.text}>
              Dear <strong> Participant,</strong>
            </p>
          </div>
          <div id="dynamic-content" className={styles.text}>
            {HtmlParser(getStorage("study_synopsis"))}
          </div>
          <div className={styles.footer}>
            <input
              className={styles.input}
              type="checkbox"
              name="termsAndConditions"
              value={checked}
              onClick={handleChange}
            />
            <p className={styles.agreeTypography}> I agree</p>
            {error && <p className={styles.error}>Please agree to continue.</p>}
          </div>
        </div>

        <Button
          onClick={handleSubmit}
          variant="contained"
          style={Styles.Button}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Terms;

const Styles = {
  Button: {
    textTransform: "none",
    letterSpacing: 1,
    fontSize: "20px",
    fontFamily: "Avenir",
    backgroundColor: "#294199",
    padding: "16px 64px",
    margin: "10px 0 0 30px",
    color: "#f8f8f8",
    fontWeight: "700",
    alignSelf: "flex-end",
    minWidth: "277px",
  },
};
