import { Toolbar } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { Route, Routes } from "react-router-dom";
import logo from "./assets/velocity.png";
import ErrorPage from "./pages/error";
import Start from "./pages/start";
import SurveyForm from "./pages/survey";
import Terms from "./pages/terms";
import Thankyou from "./pages/thankYou";
import Register from "./pages/register";
// import { initTrackingConfig } from "@velocity/tracking";
// import { domainName, firehoseName } from "./config/config";
export default function App() {
  // initTrackingConfig(`${domainName}clickstream`, firehoseName, "campaign");

  return (
    <div data-testid="appBar">
      <AppBar
        position="static"
        style={{
          boxShadow: " 7px 10px 12px -5px  rgba(0,0,0,0.2)",
          backgroundColor: "#ffffff",
        }}
      >
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            alt="logo"
            style={{ height: "100px", width: "150px", objectFit: "contain" }}
            src={logo}
          />
        </Toolbar>
      </AppBar>

      <Routes>
        <Route exact path="/error" element={<ErrorPage />} />

        <Route exact path="/terms" element={<Terms />} />

        <Route exact path="/survey" element={<SurveyForm />} />

        <Route exact path="/thankyou" element={<Thankyou />} />

        <Route exact path="/start" element={<Start />} />

        <Route exact path="/register" element={<Register />} />

        <Route path="/" element={<Start />} />
      </Routes>
    </div>
  );
}
