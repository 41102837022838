import styles from "./styles.module.css";

const Modal = ({ children, isOpen, onClose }) => {
  return (
    <div
      onClick={onClose}
      className={`${styles.modal} ${isOpen ? styles.open : styles.close}`}
      data-testid="modal"
    >
      {children}
    </div>
  );
};

export default Modal;
