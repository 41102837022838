import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SelectDatepicker } from "react-select-datepicker";
import { Button, Form, Input } from "semantic-ui-react";
import { registerPatientAPI } from "../api/campaign";
import Modal from "../components/modal";
import LoadingModal from "../components/modal/loadingModal";
import "../styles/datePicker.css";
import styles from "../styles/register.module.css";
import { getStorage, setStorage } from "../utils/storage";
// import { getDeviceProperties, useTracking } from "@velocity/tracking";
export const Register = () => {
  // const { trackEvent } = useTracking({
  //   custom_properties: {
  //     studyId: getStorage("encrypted_study_id"),
  //   },
  // });

  const [loading, setLoading] = React.useState(false);
  const [details, setDetails] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    zipCode: "",
  });

  const [errors, setErrors] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    zipCode: "",
    dob: "",
  });

  const [date, setDate] = React.useState({
    dob: "",
    month: null,
    day: null,
    year: null,
  });

  const generateEvent = (event) => {
    // trackEvent({
    //   event_type: event || "on_blur",
    //   time_stamp: new Date().toISOString(),
    //   agent_properties: getDeviceProperties(),
    //   event_properties: {
    //     ...details,
    //     dob: date.dob,
    //   },
    //   custom_properties: {
    //     studyId: getStorage("encrypted_study_id"),
    //   },
    // });
  };

  const navigation = useNavigate();

  // istanbul ignore next
  useEffect(() => {
    if (date.dob) {
      checkDateOfBirth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date.dob]);

  // istanbul ignore next
  const checkDateOfBirth = () => {
    let errorsFlag = { ...errors };
    if (!date.dob) {
      errorsFlag.dob = "Please select Date of Birth";
    } else {
      errorsFlag.dob = "";
    }
    setErrors(errorsFlag);
  };
  // istanbul ignore next
  const onDateChange = (value) => {
    let formattedDate = `${
      value?.getMonth() + 1
    }-${value?.getDate()}-${value?.getFullYear()}`;

    if (value && value.getMonth && value.getFullYear && value.getDate) {
      setDate({
        dob: formattedDate,
        selectedDay: value?.getDate(),
        selectedMonth: value?.getMonth() + 1,
        selectedYear: value?.getFullYear(),
      });
    } else {
      console.log("incorrect date ");
    }
  };

  const handleChange = (e) => {
    inputValidation(e);
    const { name, value } = e.target;
    setDetails((prev) => ({ ...prev, [name]: value }));
  };

  const inputValidation = (e) => {
    const { name, value } = e.target;
    let errorsFlag = { ...errors };

    const nameRegex = /^[a-zA-Z\s.]*$/;
    const mobileNumberRegex = /^[0-9]{10}$/;

    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

    const zipCodeRegex = /^\d{5}$/;

    switch (name) {
      case "firstName":
      case "lastName":
        if (value.length === 0) {
          errorsFlag[name] = `Please enter ${
            name === "firstName" ? "First" : "Last"
          } Name.`;
        } else if (
          !nameRegex.test(value) ||
          value.length < 2 ||
          value.length > 50
        ) {
          errorsFlag[name] = `Please enter a valid ${
            name === "firstName" ? "First" : "Last"
          } Name.`;
        } else {
          errorsFlag[name] = "";
        }
        break;
      case "phone":
        if (value.length === 0) {
          errorsFlag.phoneNumber = "Please enter Mobile Number.";
        } else if (!mobileNumberRegex.test(value)) {
          errorsFlag.phoneNumber = "Please enter a valid Mobile Number.";
        } else {
          errorsFlag.phoneNumber = "";
        }
        break;
      case "email":
        if (value.length === 0) {
          errorsFlag.email = "Please enter Email ID.";
        } else if (!emailRegex.test(value)) {
          errorsFlag.email = "Please enter a valid Email ID.";
        } else {
          errorsFlag.email = "";
        }
        break;
      case "zipCode":
        if (value.length === 0) {
          errorsFlag.zipCode = "Please enter Zip Code.";
        } else if (!zipCodeRegex.test(value)) {
          errorsFlag.zipCode = "Please enter a valid Zip Code.";
        } else {
          errorsFlag.zipCode = "";
        }
        break;
      // istanbul ignore next
      default:
        break;
    }

    setErrors(errorsFlag);
  };
  // istanbul ignore next
  const getButtonStatus = () => {
    const hasEmptyField = Object.values(details).some(
      (input) => input.length === 0
    );
    const hasError = Object.values(errors).some((error) => error.length > 1);

    const hasDateError = Object.values(date).some((date) => date.length === 0);

    return hasEmptyField || hasError || hasDateError;
  };

  // istanbul ignore next
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const data = {
      ...details,
      dob: date.dob,
    };

    const existingData = getStorage("patientDetails");
    const existingRefId = getStorage("refId");

    if (existingData && existingRefId) {
      const currentData = JSON.stringify(data);

      if (existingData === currentData) {
        navigation("/survey");
        return;
      }
    }

    setStorage("patientDetails", JSON.stringify(data));

    try {
      const response = await registerPatientAPI(data);

      const refId = response?.data?.patient?.patientId;
      setStorage(
        "patient_exists",
        response?.message === "Patient exists already" &&
          response?.data.patient.ctmsPatientId.id.length > 1
      );
      if (refId) {
        setStorage("saveDobValue", date.dob);
        setStorage("refId", refId);

        navigation("/survey");
      }
    } catch {
      setStorage(
        "error_message",
        "Thank you for your interest! Unfortunately, we could not process your request due to a server issue. Please try again, or contact us at info@velocityclinical.com if the problem persists."
      );
      navigation("/error");
    }
  };

  return (
    <div className={styles.page}>
      <Modal isOpen={loading}>
        <LoadingModal />
      </Modal>
      <div className={styles.container}>
        <form className={styles.form}>
          <div className={styles.row}>
            <Form.Field className={styles.field}>
              <label htmlFor="firstName" className={styles.inputLabel}>
                First Name <span className={styles.span}>*</span>
              </label>
              <Input
                name="firstName"
                id="firstName"
                className={styles.input}
                value={details.firstName}
                onBlur={(e) => {
                  inputValidation(e);
                  generateEvent();
                }}
                type="text"
                onChange={handleChange}
              />

              {errors.firstName && (
                <p className={styles.errorText}>{errors.firstName}</p>
              )}
            </Form.Field>
            <Form.Field className={styles.field}>
              <label htmlFor="lastName" className={styles.inputLabel}>
                Last Name <span className={styles.span}>*</span>
              </label>
              <Input
                name="lastName"
                id="lastName"
                className={styles.input}
                value={details.lastName}
                onBlur={(e) => {
                  inputValidation(e);
                  generateEvent();
                }}
                type="text"
                onChange={handleChange}
              />

              {errors.lastName && (
                <p className={styles.errorText}>{errors.lastName}</p>
              )}
            </Form.Field>
          </div>
          <div className={styles.row}>
            <Form.Field className={styles.field}>
              <label htmlFor="dob" className={styles.inputLabel}>
                Date of Birth <span className={styles.span}>*</span>
              </label>

              <SelectDatepicker
                className="SelectDatepicker"
                onBlur={() => {
                  // istanbul ignore next
                  checkDateOfBirth();
                  // istanbul ignore next
                  generateEvent();
                }}
                name="dob"
                hideLabels
                id="dob"
                value={date.dob}
                selectedMonth={date.month}
                selectedYear={date.year}
                selectedDay={date.day}
                onDateChange={onDateChange}
                maxDate={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 18)
                  )
                }
                labels={{
                  dayPlaceholder: "Day",
                  monthPlaceholder: "Month",
                  yearPlaceholder: "Year",
                }}
              />
              {
                // istanbul ignore next
                errors.dob && (
                  <p
                    style={{
                      color: "#FF0000",
                      marginLeft: "5px",
                      marginTop: "5px",
                    }}
                  >
                    {errors.dob}
                  </p>
                )
              }
            </Form.Field>
            <Form.Field className={styles.field}>
              <label htmlFor="phone" className={styles.inputLabel}>
                Mobile Number <span className={styles.span}>*</span>
              </label>
              <Input
                name="phone"
                id="phone"
                className={styles.input}
                value={details.phone}
                onBlur={(e) => {
                  inputValidation(e);
                  generateEvent();
                }}
                onChange={handleChange}
                type="number"
              />

              {errors.phoneNumber && (
                <p className={styles.errorText}>{errors.phoneNumber}</p>
              )}
            </Form.Field>
          </div>
          <div className={styles.row}>
            <Form.Field className={styles.field}>
              <label htmlFor="email" className={styles.inputLabel}>
                Email ID <span className={styles.span}>*</span>
              </label>
              <Input
                name="email"
                id="email"
                type="email"
                className={styles.input}
                value={details.email}
                onBlur={(e) => {
                  inputValidation(e);
                  generateEvent();
                }}
                onChange={handleChange}
              />

              {errors.email && (
                <p className={styles.errorText}>{errors.email}</p>
              )}
            </Form.Field>
            <Form.Field className={styles.field}>
              <label htmlFor="zipCode" className={styles.inputLabel}>
                Zip Code <span className={styles.span}>*</span>
              </label>
              <Input
                name="zipCode"
                id="zipCode"
                className={styles.input}
                value={details.zipCode}
                onBlur={(e) => {
                  inputValidation(e);
                  generateEvent();
                }}
                type="number"
                onChange={handleChange}
              />
              {errors.zipCode && (
                <p className={styles.errorText}>{errors.zipCode}</p>
              )}
            </Form.Field>
          </div>
        </form>

        <Button
          disabled={getButtonStatus()}
          style={
            // istanbul ignore next
            getButtonStatus() ? Styles.disabledButton : Styles.Button
          }
          onClick={handleSubmit}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

const Styles = {
  Button: {
    textTransform: "none",
    letterSpacing: 1,
    fontSize: "20px",
    width: "277px",
    height: "60px",
    fontFamily: "Avenir",
    backgroundColor: "#294199",
    padding: "16px 64px",
    margin: "10px 0 0 30px",
    color: "#f8f8f8",
    fontWeight: "700",
    alignSelf: "flex-end",
  },

  disabledButton: {
    textTransform: "none",
    letterSpacing: 1,
    fontSize: "20px",
    width: "277px",
    height: "60px",
    fontFamily: "Avenir",
    backgroundColor: "#7a7a7a",
    padding: "16px 64px",
    margin: "10px 0 0 30px",
    color: "#f8f8f8",
    fontWeight: "700",
    alignSelf: "flex-end",
  },
};
export default Register;
