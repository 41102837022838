import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import App from "./app";
import { BrowserRouter } from "react-router-dom";
import "./assets/fonts/Avenir35Light/font.woff";
import "./assets/fonts/Avenir35Light/font.woff2";
import MetaPixel from "./utils/metaPixel";

ReactDOM.render(
  <BrowserRouter>
    <MetaPixel />
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
