import React from "react";
import { Button } from "semantic-ui-react";
import {
  SchedulingURL,
  message1,
  message2,
  message3,
  message4,
  message5,
} from "../config/config";
import styles from "../styles/thankyou.module.css";
import { getStorage } from "../utils/storage";
// import { useTracking } from "@velocity/tracking";
import HtmlParser from "react-html-parser";

export const ThankYouPage = () => {
  const siteId = getStorage("encrypted_ctms_site_id"),
    studyId = getStorage("encrypted_ctms_study_id"),
    ctmsPatientId = getStorage("encrypted_ctms_patient_id"),
    prescreeningStatus = getStorage("prescreening_status") === "pass",
    duplicatedStatus = getStorage("duplicated_status"),
    schedulingStatus = getStorage("self_scheduling"),
    enrollmentSuccess = getStorage("enrollment_success"),
    vcPatientId = getStorage("encrypted_vc_patient_id"),
    surveyId = getStorage("encrypted_survey_id"),
    vaccineDate = getStorage("vaccineDate");
  const isVaccineDateDefined = !(
    vaccineDate === "undefined" ||
    vaccineDate === undefined ||
    vaccineDate === null
  );

  // useTracking({
  //   custom_properties: {
  //     studyId: getStorage("encrypted_study_id"),
  //     ctmsStudyId: studyId,
  //     ctmsPatientId,
  //     vcPatientId,
  //     siteId,
  //     surveyId,
  //     prescreeningStatus,
  //     schedulingStatus,
  //     enrollmentSuccess,
  //   },
  // });

  let url = `${SchedulingURL}/?survey_id=${surveyId}&patient_id=${vcPatientId}&study_id=${studyId}&site_id=${siteId}&ctms_patient_id=${ctmsPatientId}`;
  url = url + (isVaccineDateDefined ? `&vaccine_date=${vaccineDate}` : "");

  const showPrescreenStatusMessage = (
    prescreeningStatus,
    schedulingStatus,
    duplicatedStatus,
    enrollmentSuccess
  ) => {
    if (duplicatedStatus) {
      if (duplicatedStatus === "ACTIVE_PARTICIPANT")
        return <ThankYouMessage message={message1} />;
      if (duplicatedStatus === "INELIGIBLE_PARTICIPANT")
        return <ThankYouMessage message={message2} />;
      if (duplicatedStatus === "COMPLETED_PARTICIPANT")
        return <ThankYouMessage message={message3} />;
      if (duplicatedStatus === "PARTICIPANT_STUDY")
        return <ThankYouMessage message={message4} />;
      if (duplicatedStatus === "ACTIVE_PARTICIPANT_OTHER_STUDY")
        return <ThankYouMessage message={message5} />;
    } else if (prescreeningStatus) {
      if (schedulingStatus && enrollmentSuccess) {
        return <ScheduleMessage />;
      } else {
        return <NoScheduleMessage />;
      }
    } else {
      return <PrescreenFailMessage />;
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.content}>
          {showPrescreenStatusMessage(
            prescreeningStatus,
            schedulingStatus,
            duplicatedStatus,
            enrollmentSuccess
          )}
        </div>

        {prescreeningStatus && schedulingStatus && enrollmentSuccess ? (
          <Button style={Styles.Button}>
            <a
              href={url}
              style={{
                color: "white",
              }}
            >
              Schedule&nbsp;Appointment
            </a>
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const ScheduleMessage = () => {
  return (
    <div>
      <p className={styles.heading}> Congratulations!</p>
      <p className={styles.typography}>
        Based on the answers provided you might be a good fit to participate in
        the study. The next step is to schedule a visit to our clinic.
      </p>
    </div>
  );
};
const PrescreenFailMessage = () => {
  return (
    <div>
      <p className={styles.heading}>
        <strong>Thank you for your time.</strong>
      </p>
      <p className={styles.typography}>
        Based on the answers you provided it looks like you are not currently
        eligible for this study. If you would like to learn more about other
        clinical trials that may be a better fit, Please contact us at &nbsp;{" "}
        <a href="https://velocitypatients.com/contact/">
          https://velocitypatients.com/contact/
        </a>{" "}
        <br></br> <br></br>
        We will continue to reach out when we think we may have a study that may
        be a good fit.
      </p>
    </div>
  );
};
const NoScheduleMessage = () => {
  return (
    <div>
      <p className={styles.heading}> Congratulations!</p>
      <p className={styles.typography}>
        Based on the answers provided you might be a good fit to participate in
        the study. You will receive a call from one of our site recruiters to
        schedule your site visit.
      </p>
    </div>
  );
};
const ThankYouMessage = ({ message }) => {
  return <div className={styles.typography}> {HtmlParser(message)}</div>;
};

const Styles = {
  Button: {
    textTransform: "none",
    letterSpacing: 1,
    fontSize: "20px",
    height: " 60px",
    fontFamily: "Avenir",
    backgroundColor: "#294199",
    padding: "16px 64px",
    margin: "10px 0 0 30px",
    color: "#f8f8f8",
    fontWeight: "700",
    alignSelf: "flex-end",
  },
};

// istanbul ignore next
if (window.matchMedia("(max-width: 767px)").matches) {
  Styles.Button.padding = "8px 32px"; // Modify the margin property for mobile view
  // Modify the margin property for mobile view
}
export default ThankYouPage;
