import {
  clientId,
  clientSecret,
  domainName,
  platformAPI,
} from "../config/config";
import { generateAuthToken } from "../utils/generateAuthToken";

export const getCampaignAPI = async (study_id) => {
  const result = await fetch(`${domainName}campaigns/${study_id}`);
  let getCampaignAPIResponse = await result.json();
  return getCampaignAPIResponse;
};

export const registerPatientAPI = async (data) => {
  const token = generateAuthToken(clientId, clientSecret);

  const result = await fetch(`${platformAPI}patients`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-source": "campaign",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });

  if (!result.ok) {
    throw new Error(`API request failed with status code: ${result.status}`);
  }
  let registerPatientAPIResponse = await result.json();
  return registerPatientAPIResponse;
};

export const submitCampaignAPI = async (encryptedStudyId, data) => {
  const result = await fetch(`${domainName}campaigns/${encryptedStudyId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (!result.ok) {
    throw new Error(`API request failed with status code: ${result.status}`);
  }
  let submitCampaignAPIResponse = await result.json();
  return submitCampaignAPIResponse;
};

export const tagPatientSource = (data) => {
  fetch(`${domainName}campaigns/source/utm-params`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).catch((error) => {
    console.error(error);
  });
};
