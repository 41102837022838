// import { useTracking } from "@velocity/tracking";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Model, StylesManager } from "survey-core";
import "survey-core/defaultV2.css";
import { Survey } from "survey-react-ui";
import { submitCampaignAPI, tagPatientSource } from "../api/campaign";
import { registerDatePicker } from "../components/customSurvey/datePickerClass";
import Modal from "../components/modal";
import LoadingModal from "../components/modal/loadingModal";
import "../styles/surveyCustom.css";
import "../styles/surveyElement.css";
import {
  surveyCompleted,
  surveyQuestionChange,
  surveyStart,
  surveyValueChange,
} from "../tracking/survey";
import { vaccineDateFormat } from "../utils/dateFormat";
import { getStorage, setStorage } from "../utils/storage";

const SurveyForm = () => {
  const [modal, setModal] = useState(false);
  StylesManager.applyTheme("defaultV2");
  const navigation = useNavigate();

  // registers custom widget for date-picker in survey
  registerDatePicker();

  const survey_template = getStorage("survey_json");

  const survey = new Model(survey_template);

  // const { getDispatcher } = useTracking({
  //   custom_properties: {
  //     studyId: getStorage("encrypted_study_id"),
  //     patientRefId: getStorage("refId"),
  //   },
  // });
  // const dispatch = getDispatcher();

  useEffect(() => {
    let uparams = sessionStorage.getItem("urlParams");
    if (sessionStorage.getItem("refId")) {
      uparams = JSON.parse(uparams);
      let patientSouceData = {
        ref_id: sessionStorage.getItem("refId"),
        vc_study_id: uparams.studyId,
        utm_campaign: uparams.utmCampaign,
        utm_source: uparams.utmSource,
        utm_medium: uparams.utmMedium,
        utm_content: uparams.utmContent,
        utm_term: uparams.utmTerm,
      };
      tagPatientSource(patientSouceData);
    }
    // surveyStart(dispatch);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // registers custom widget for date-picker in survey
  registerDatePicker();

  const questions = survey.getAllQuestions();

  questions.forEach((question) => {
    question.id = question.name;
  });

  let previewNavItem = survey.navigationBar.actions.find(
    (x) => x.id === "sv-nav-prev"
  );

  previewNavItem.visible = true;

  survey.setVariable("date_of_birth", getStorage("saveDobValue"));

  /* istanbul ignore next */
  if (survey.currentPageNo === 0) {
    previewNavItem.visible = true;
    previewNavItem.enabled = false;
  }
  survey.onCurrentPageChanged.add(() => {
    // surveyQuestionChange(dispatch, survey);
    previewNavItem.enabled = true;
    /* istanbul ignore next */
    if (survey.currentPageNo === 0) {
      previewNavItem.visible = true;
      previewNavItem.enabled = false;
    }
  });
  //the below line is responsible to restrict the survey other comments to length 80
  survey.maxOthersLength = 80;
  const saveState = (survey) => {
    let res = { currentPageNo: survey.currentPageNo, data: survey.data };
    //Here should be the code to save the data into your database
    setStorage("saveState", JSON.stringify(res));
  };

  const loadState = (survey) => {
    //Here should be the code to load the data from your database
    let storageSt = getStorage("saveState") || "";
    let res = {};
    if (storageSt) res = JSON.parse(storageSt);
    //Set the loaded data into the survey.
    if (res.currentPageNo) survey.currentPageNo = res.currentPageNo;
    if (res.data) survey.data = res.data;
  };
  survey.onValueChanged.add(function (survey, options) {
    saveState(survey);
    // surveyValueChange(dispatch, survey);
  });
  survey.onCurrentPageChanged.add(function (survey, options) {
    saveState(survey);
  });
  /* istanbul ignore next */
  survey.onDynamicPanelItemValueChanged.add(function (survey, options) {
    saveState(survey);
  });
  /* istanbul ignore next */
  survey.onMatrixCellValueChanged.add(function (survey, options) {
    saveState(survey);
  });

  survey.completeText = "Submit";

  const alertResults = useCallback(async (sender) => {
    setModal(true);
    const results = sender.data;

    results["date_of_birth"] = getStorage("saveDobValue");
    let surveyData = results;

    await updatePatientData(surveyData);

    // surveyCompleted(dispatch);
    saveState(survey);

    // istanbul ignore next
    if (
      getStorage("prescreening_status") === "pass" ||
      getStorage("prescreening_status") === "fail" ||
      getStorage("duplicated_status")
    ) {
      navigation("/thankyou");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  survey.showCompletedPage = false;
  survey.onComplete.add(alertResults);

  const updatePatientData = async (json) => {
    try {
      let dobInput = getStorage("saveDobValue");
      survey.clearIncorrectValues();

      const res = await submitCampaignAPI(getStorage("encrypted_study_id"), {
        patient_ref_id: getStorage("refId"),
        patient_exists: getStorage("patient_exists"),
        survey_response: {
          ...json,
          date_of_birth: dobInput,
        },
      });

      res.encrypted_ctms_site_id &&
        setStorage("encrypted_ctms_site_id", res.encrypted_ctms_site_id);
      res.encrypted_ctms_study_id &&
        setStorage("encrypted_ctms_study_id", res.encrypted_ctms_study_id);
      res.prescreening_status &&
        setStorage("prescreening_status", res.prescreening_status);

      res.duplicated_status &&
        setStorage("duplicated_status", res.duplicated_status);
      res.self_scheduling && setStorage("self_scheduling", res.self_scheduling);
      res.encrypted_ctms_patient_id &&
        setStorage("encrypted_ctms_patient_id", res.encrypted_ctms_patient_id);
      res.enrollment_success &&
        setStorage("enrollment_success", res.enrollment_success);
      res.encrypted_vc_patient_id &&
        setStorage("encrypted_vc_patient_id", res.encrypted_vc_patient_id);

      setStorage(
        "vaccineDate",
        // istanbul ignore next
        survey.data.vaccine_date
          ? vaccineDateFormat(new Date(survey.data.vaccine_date))
          : undefined
      );
    } catch (e) {
      setStorage(
        "error_message",
        "Thank you for your interest! Unfortunately, we could not process your request due to a server issue. Please try again, or contact us at info@velocityclinical.com if the problem persists."
      );
      navigation("/error");
    }
  };

  loadState(survey);
  return (
    <>
      <Modal isOpen={modal}>
        <LoadingModal />
      </Modal>
      <Survey model={survey} />
    </>
  );
};

export default SurveyForm;
